var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.widthDialog,"persistent":""},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},[_c('v-card',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex justify-space-between text--body-5-22 pb-2"},[_c('span',[_vm._v(_vm._s(_vm.$t("edit-new-order")))]),_c('v-icon',{staticClass:"cursor-pointer",on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1),_c('v-layout',{attrs:{"column":""}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-layout',{staticClass:"border-primary rounded pa-2 px-lg-6 py-sm-3"},[_c('v-row',[_c('v-col',{staticClass:"col-12",attrs:{"cols":"sm-6"}},[_c('v-layout',{attrs:{"column":""}},[_c('v-layout',{attrs:{"justify-start":""}},[_c('h4',[_vm._v(_vm._s(_vm.$t("receiver")))])]),_c('v-layout',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',[_c('div',[_vm._v(" "+_vm._s(_vm.$t("receiver_phone"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("icon_required"))+" ")])]),_c('validation-provider',{attrs:{"name":"phone","rules":_vm.phoneRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","pattern":"\\d*","outlined":"","dense":"","error-messages":errors,"maxlength":_vm.maximumPhone,"placeholder":_vm.$t('place_holders.type_phone')},on:{"keypress":function($event){return _vm.isNumber($event)},"input":function($event){return _vm.inputPhone($event)}},model:{value:(_vm.detailData.receiverPhone),callback:function ($$v) {_vm.$set(_vm.detailData, "receiverPhone", $$v)},expression:"detailData.receiverPhone"}}),_c('div',{staticClass:"pos-rel"},[_c('v-menu',{staticClass:"list-receiver",attrs:{"nudge-width":200,"offset-x":"","attach":""},model:{value:(_vm.menuPhone),callback:function ($$v) {_vm.menuPhone=$$v},expression:"menuPhone"}},[_c('v-card',_vm._l((_vm.listReceiver),function(item,index){return _c('v-list',{key:index},[_c('v-list-item',{on:{"click":function($event){return _vm.choosePhone(item.phone)}}},[_c('v-layout',{attrs:{"column":""}},[_c('span',{staticClass:"text--body-5-14"},[_vm._v(" "+_vm._s(item.phone)+" - "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"text--body-5-12"},[_vm._v(" "+_vm._s(((item.address) + ", " + (item.communeName) + ", " + (item.districtName) + ", " + (item.provinceName)))+" ")])])],1)],1)}),1)],1)],1)]}}],null,true)})],1)],1)],1),_c('v-layout',[_c('v-row',[_c('v-col',[_c('div',[_vm._v(" "+_vm._s(_vm.$t("receiver_full_name"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("icon_required"))+" ")])]),_c('validation-provider',{attrs:{"name":"require","vid":"fullName","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"maxlength":_vm.maximumSmallText,"placeholder":_vm.$t('place_holders.type_full_name')},model:{value:(_vm.detailData.receiverName),callback:function ($$v) {_vm.$set(_vm.detailData, "receiverName", $$v)},expression:"detailData.receiverName"}})]}}],null,true)})],1)],1)],1),_c('v-layout',{attrs:{"column":""}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("province"))+"/"+_vm._s(_vm.$t("district"))+"/"+_vm._s(_vm.$t("commune"))+" "),_c('span',{staticClass:"error--text"},[_vm._v("*")])]),_c('v-row',[_c('validation-provider',{staticClass:"container col-sm-4 col-12",attrs:{"name":"selectRequire","vid":"provinceOrder","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"inputProvinceOrder",staticClass:"rounded",attrs:{"outlined":"","dense":"","item-text":"name","item-value":"code","hide-no-data":"","items":_vm.lstProvince,"filter":_vm.filterItems,"placeholder":_vm.$t('place_holders.province'),"error-messages":errors},on:{"change":_vm.onChangeProvinceOrder,"blur":_vm.calculateShipFee},model:{value:(_vm.detailData.editProvinceOrderSelected),callback:function ($$v) {_vm.$set(_vm.detailData, "editProvinceOrderSelected", $$v)},expression:"detailData.editProvinceOrderSelected"}})]}}],null,true)}),_c('validation-provider',{ref:"districtOrder",staticClass:"container col-sm-4 col-12 col-12 py-0 py-sm-3",attrs:{"name":"selectRequire","vid":"districtOrder","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"inputDistrictOrder",staticClass:"rounded",attrs:{"outlined":"","dense":"","item-text":"name","item-value":"code","hide-no-data":"","items":_vm.lstDistricts,"filter":_vm.filterItems,"placeholder":_vm.$t('place_holders.district'),"error-messages":errors,"disabled":_vm.districtDisableds},on:{"change":_vm.onChangeDistrictOrder},model:{value:(_vm.detailData.editDistrictOrderSelected),callback:function ($$v) {_vm.$set(_vm.detailData, "editDistrictOrderSelected", $$v)},expression:"detailData.editDistrictOrderSelected"}})]}}],null,true)}),_c('validation-provider',{ref:"communeOrder",staticClass:"container col-sm-4",attrs:{"name":"selectRequire","vid":"communeOrder","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"inputCommuneOrder",staticClass:"rounded",attrs:{"outlined":"","dense":"","item-text":"name","item-value":"code","hide-no-data":"","items":_vm.lstCommunes,"filter":_vm.filterItems,"placeholder":_vm.$t('place_holders.commune'),"error-messages":errors,"disabled":_vm.communeDisableds},on:{"change":_vm.onChangeCommuneOrder},model:{value:(_vm.detailData.editCommuneOrderSelected),callback:function ($$v) {_vm.$set(_vm.detailData, "editCommuneOrderSelected", $$v)},expression:"detailData.editCommuneOrderSelected"}})]}}],null,true)})],1)],1),_c('v-layout',[_c('v-row',[_c('v-col',[_c('div',[_vm._v(" "+_vm._s(_vm.$t("custom_address"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("icon_required"))+" ")])]),_c('validation-provider',{attrs:{"name":"require","vid":"customAddress","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","maxlength":_vm.maximumSmallText,"error-messages":errors,"placeholder":_vm.$t('place_holders.type_custom_address')},model:{value:(_vm.detailData.receiverAddress),callback:function ($$v) {_vm.$set(_vm.detailData, "receiverAddress", $$v)},expression:"detailData.receiverAddress"}})]}}],null,true)})],1)],1)],1)],1)],1),_c('v-col',[_c('v-layout',{attrs:{"column":""}},[_c('v-layout',{attrs:{"justify-start":""}},[_c('h4',[_vm._v(_vm._s(_vm.$t("order_info")))])]),_c('v-layout',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',[_c('div',[_vm._v(" "+_vm._s(_vm.$t("product_name"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("icon_required"))+" ")])]),_c('validation-provider',{attrs:{"name":"require","vid":"productDescription","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"rows":"3","outlined":"","maxlength":_vm.maximumSmallText,"error-messages":errors,"placeholder":_vm.$t('place_holders.product_name')},model:{value:(_vm.detailData.description),callback:function ($$v) {_vm.$set(_vm.detailData, "description", $$v)},expression:"detailData.description"}})]}}],null,true)})],1)],1)],1),_c('div',[_vm._v(" "+_vm._s(_vm.$t("calculate_size"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("icon_required"))+" ")]),_c('v-icon',[_vm._v("mdi-compare-horizontal")]),_vm._v(" "+_vm._s(_vm.$t("tranfer_weight"))+": "+_vm._s(_vm.computedEstimateWeight( _vm.detailData.length, _vm.detailData.width, _vm.detailData.height ))+_vm._s(_vm.$t("kg"))+" ")],1),_c('v-row',{staticClass:"justify-center"},[_c('validation-provider',{staticClass:"container col-sm-4 col-12",attrs:{"name":"require","vid":"length","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pr-sm-2",attrs:{"outlined":"","dense":"","suffix":"cm","maxlength":"5","error-messages":errors,"placeholder":_vm.$t('place_holders.length')},on:{"keypress":function($event){return _vm.isNumberFloat($event)},"input":_vm.onInputLength,"paste":function($event){$event.preventDefault();return _vm.onPasteLength($event)},"blur":_vm.calculateShipFee},model:{value:(_vm.detailData.length),callback:function ($$v) {_vm.$set(_vm.detailData, "length", $$v)},expression:"detailData.length"}})]}}],null,true)}),_c('validation-provider',{staticClass:"container col-sm-4 col-12 px-sm-0 py-0 py-sm-3",attrs:{"name":"require","vid":"width","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","suffix":"cm","prepend-icon":"mdi-plus","maxlength":"5","error-messages":errors,"placeholder":_vm.$t('place_holders.width')},on:{"keypress":function($event){return _vm.isNumberFloat($event)},"input":_vm.onInputWidth,"paste":function($event){$event.preventDefault();return _vm.onPasteWidth($event)},"blur":_vm.calculateShipFee},model:{value:(_vm.detailData.width),callback:function ($$v) {_vm.$set(_vm.detailData, "width", $$v)},expression:"detailData.width"}})]}}],null,true)}),_c('validation-provider',{staticClass:"container col-sm-4 col-12",attrs:{"name":"require","vid":"height","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-sm-2",attrs:{"outlined":"","dense":"","suffix":"cm","prepend-icon":"mdi-plus","maxlength":"5","error-messages":errors,"placeholder":_vm.$t('place_holders.height')},on:{"keypress":function($event){return _vm.isNumberFloat($event)},"input":_vm.onInputHeight,"paste":function($event){$event.preventDefault();return _vm.onPasteHeight($event)},"blur":_vm.calculateShipFee},model:{value:(_vm.detailData.height),callback:function ($$v) {_vm.$set(_vm.detailData, "height", $$v)},expression:"detailData.height"}})]}}],null,true)})],1),_c('v-layout',[_c('v-row',[_c('v-col',{staticClass:"col-12 pb-0",attrs:{"cols":"sm-6"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("order_weight"))),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("icon_required")))])]),_c('validation-provider',{attrs:{"name":"orderWeight","vid":"orderWeight","rules":_vm.orderWeightRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","suffix":"kg","outlined":"","maxlength":_vm.maximumOrderWeight,"error-messages":errors,"placeholder":_vm.$t('place_holders.type_weight')},on:{"keypress":function($event){return _vm.isDecimal($event)},"input":_vm.onInputOrderWeight,"paste":function($event){$event.preventDefault();return _vm.onPasteOrderWeight($event)},"blur":_vm.calculateShipFee},model:{value:(_vm.detailData.weight),callback:function ($$v) {_vm.$set(_vm.detailData, "weight", $$v)},expression:"detailData.weight"}})]}}],null,true)})],1),_c('v-col',{staticClass:"col-12 pt-0 pt-sm-3",attrs:{"cols":"sm-6"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("cod"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t("icon_required")))])]),_c('validation-provider',{attrs:{"name":"require","vid":"cod","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{class:_vm.codValueError
                              ? 'error--text v-input--has-state'
                              : '',attrs:{"dense":"","outlined":"","hide-details":_vm.codValueHideError,"maxlength":_vm.maximumPriceFm,"error-messages":errors,"placeholder":_vm.$t('place_holders.type_cod')},on:{"keypress":function($event){return _vm.isNumberFloat($event)},"input":_vm.onInputCOD,"paste":function($event){$event.preventDefault();return _vm.onPasteCOD($event)}},model:{value:(_vm.detailData.codValue),callback:function ($$v) {_vm.$set(_vm.detailData, "codValue", $$v)},expression:"detailData.codValue"}}),(_vm.codValueError)?_c('div',{staticClass:"v-messages theme--light error--text mt-1 ml-3",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(_vm.codValueError)+" ")])])]):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-layout',[_c('v-row',[_c('v-col',{staticClass:"col-12 pt-0 pt-sm-3",attrs:{"cols":"sm-6"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("order_value"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("icon_required"))+" ")])]),_c('validation-provider',{attrs:{"name":"require","vid":"orderValue","rules":_vm.requireRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('v-text-field',{class:_vm.orderValueError
                              ? 'error--text v-input--has-state'
                              : '',attrs:{"outlined":"","dense":"","hide-details":_vm.orderValueHideError,"error-messages":errors,"maxlength":_vm.maximumPriceFm,"placeholder":_vm.$t('place_holders.type_value_order')},on:{"keypress":function($event){return _vm.isNumberFloat($event)},"input":_vm.onInputOrderValue,"paste":function($event){$event.preventDefault();return _vm.onPasteOrderValue($event)}},model:{value:(_vm.detailData.orderValue),callback:function ($$v) {_vm.$set(_vm.detailData, "orderValue", $$v)},expression:"detailData.orderValue"}}),(_vm.orderValueError)?_c('div',{staticClass:"v-messages theme--light error--text mt-1 ml-3",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(_vm.orderValueError)+" ")])])]):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-layout',[_c('v-row',[_c('v-col',[_c('div',[_vm._v(" "+_vm._s(_vm.$t("payer"))+" "),_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t("icon_required"))+" ")])]),_c('v-radio-group',{staticClass:"mt-0",attrs:{"row":""},on:{"change":_vm.onChangeServiceFeePayer},model:{value:(_vm.detailData.paymentByValue),callback:function ($$v) {_vm.$set(_vm.detailData, "paymentByValue", $$v)},expression:"detailData.paymentByValue"}},[_c('v-radio',{attrs:{"label":_vm.$t('sender'),"value":_vm.serviceFeePayersConst.sender}}),_c('v-radio',{attrs:{"label":_vm.$t('receiver'),"value":_vm.serviceFeePayersConst.receiver}})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-layout',{staticClass:"mt-4 d-flex justify-end"},[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.handleReType}},[_vm._v(_vm._s(_vm.$t("re_type")))]),_c('v-btn',{staticClass:"ml-3",attrs:{"disabled":invalid || _vm.detailData.paymentByValue === '-' || _vm.isInvalidAction,"color":"primary"},on:{"click":_vm.onSaveData}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }